@import "./scss-variables";

body {
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none !important;
}

img {
    width: 100%;
    max-width: 100%;
}

.spinner-container {
    display: flex;
    margin: 0 auto;
    align-self: center;
    height: calc(100vh - (160px + 4rem));
}

.flex-container {
    display: flex;
}

.square-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;
    padding-bottom: 100%;

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        object-fit: cover;
        height: 100%;
    }
}

.old-price {
    text-decoration: line-through;
    color: $red;
}

.title {
    h1, h2, h3, h4, h6 {
        font-family: 'Raleway', sans-serif;
    }

    .price-container {
        h1, h2, h3, h4, h6 {
            font-family: 'Roboto', sans-serif;
        }
    }
}

span.btn {
    color: $blue;
    cursor: pointer;
}


@media (min-width: 992px) {

    .navbar-left {
        justify-content: flex-start;
    }

    .navbar-right {
        justify-content: flex-end;
    }

    .bp3-navbar {
        height: 50px;
    }

}

.slick-slider  {
    .slick-prev, .slick-next {
        &::before {
            color: $black-secondary;
        }
    }
}

.bp3-dark {

    .header {
        background-color: $black-primary;
    }

    .logotipo, .whatsapp-icon, .instagrama {
        fill: $white-primary;
    }

    .bp3-icon-moon {
        path {
            fill: $black-primary;
        }
    }

    .home-filters__button {

        &:hover {
            background-color: transparentize($white-primary, .95);
        }

    }

    .selectedFilter {
        background-color: $black-secondary !important;
    }

    .bp3-card {
        background-color: $black-secondary !important;

        .tarjeta_season {
            color: $white-primary
        }
    }

    .product-description {
        color: transparentize($white-primary, .4);
    }

    .bp3-text-muted {
        color: $red
    }

    .slick-slider  {
        .slick-prev, .slick-next {
            &::before {
                color: $white-primary;
            }
        }
        .slick-dots {
            li {
                &.slick-active button::before {
                    color: $white-primary;
                }
                button::before {
                    color: rgba($color: $white-primary, $alpha: 0.8);
                }
            }
        }
    }

    .cart-footer__discount .bp3-form-group input {
        border: 1px solid $white-primary
    }

}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}


// form input styles
.bp3-form-group {
    position: relative;
}

.bp3-input-group {
    z-index: 3
}

.bp3-text-muted {
    color: $red
}

.liveValidateMessage {
  top: -6px;
  position: absolute;
  z-index: 1;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  color: $white-primary;
}

.liveValidateMessage--visible {
  opacity: 1;
  transform: translateY(0);
}

.liveValidateMessage-enter {
  opacity: 0;
  transform: translateY(100%);
}

.liveValidateMessage-enter-done {
  opacity: 1;
  transform: translateY(0);
  transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
  background-color: $black-additional;
}

.liveValidateMessage-exit {
  opacity: 1;
  transform: translateY(0);
}

.liveValidateMessage-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
}


.bp3-card.transparent-bkg {
    background-color: transparent !important;
    box-shadow: none !important;
}

.disclaimer {
    font-size: 10px;
}

.slick-track {
    margin: 0;
}

.thumbnail-slider-wrap {
    // margin-top: 15px;
    // height: 85px;

    .slick-cloned {
        display: none !important;
    }

    .slick-track > .slick-slide {

        opacity: .6;

        &.slick-current {
            opacity: 1;
        }
    }
    .slick-track {
        margin: 0;

        .slick-slide {
            text-align: center;
            max-width: 60px;
            margin-right: .4rem;
            cursor: pointer;
        }
    }
}
.tarjetas_de_pago {
  img {
      opacity: .6;
      max-width: 40px;
      margin-right: 1rem;

      &:hover {
          opacity: 1
      }
  }
}

.mt0 { margin-top: 0 !important }
.mt1 { margin-top: 1em !important }
.mt2 { margin-top: 2em !important }
.mt3 { margin-top: 3em !important }
.mt4 { margin-top: 4em !important }

.mb0 { margin-bottom: 0 !important }
.mb1 { margin-bottom: 1em !important }
.mb2 { margin-bottom: 2em !important }
.mb3 { margin-bottom: 3em !important }
.mb4 { margin-bottom: 4em !important }


.video-viewport video {
	width: 100%;
	height: 100%;
}

.home-video {
    margin: 0;
    padding: 0;
}