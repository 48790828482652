ul.category-list {

    padding: 0;
    padding-left: 1rem;
    list-style: none;

    li {
        cursor: pointer;
    }

    .selected--category {
        // font-weight: bolder;
        color: #137cbd;
        text-decoration: underline;
    }

}
