.cart-footer {
    h5 span, h4 span {
        font-weight: 300;
    }
    &__discount {
        display: flex;
        justify-content: flex-end;

        .bp3-form-group {
            display: inline-block;
            min-width: 260px;
            margin-right: 10px;

            .liveValidateMessage {
                top: auto;
                position: relative;
                border-radius: 0;
                padding: 0;

                &-enter-done {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    padding-left: 0.8rem;
                    padding-right: 0.8rem;
                }

            }

            input {
                height: 40px;
            }
        }

        button {
            height: 40px;
        }
    }
}
