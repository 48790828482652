@import "../Shared/styles/scss-variables";

.header {
    position: sticky;
    top: 0;
    z-index: 3;
    margin-bottom: 2rem;
    background-color: $white-bkg;
}

.logotipo {
    min-width: 360px;
    margin-bottom: -1rem;
    color: $black-primary;
    width: 100%;
}

@media (max-width: 767px) {

    .logotipo {
        min-width: 240px;
    }

    .isotipo {
        width: 56px;
    }

}

.isotipo {
    width: 69px;
    margin: -10px 20px -12px -14px;

    .isotipo-cat {
        fill: #f5f8fa;
    }

    .isotipo-circle {
        fill: #18191a;
    }
}

.navbar-right, .navbar-left {
    justify-content: center;
}

.bp3-navbar {
    height: auto !important;
}

// .username {
//     position: absolute;
//     top: -12px;
// }

.logoutBtn {
    cursor: pointer;
}

.cart-icon {

    position: relative;

    .bp3-button-text {
        position: absolute;
        top: -5px;
        right: 5px;
    }

    .cart-notification {
        width: 17px;
        height: 17px;
        background-color: $red;
        color: $white-primary;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        font-size: 12px;
        padding-top: 1px;
    }

}

.category_btn {
    background-color: transparent !important;
    min-width: auto !important;
}

.cuponHeaderStyle {
    margin-right: 1rem;
    border: 1px dashed $black-primary;
    padding: .2rem .6rem;
}

.bp3-dark .cuponHeaderStyle {
    border-color: $white-primary;
}