@import "../Shared/styles/scss-variables";

.producto-single {

     &__title {
         align-items: flex-end;

         .price-tooltip {
             position: absolute;
             right: 0;
             top: -20px;
         }
     }

     &__description p {
         margin: 0
     }

     &__sizes {
         padding-bottom: 2rem;
     }
}

.sizes-selector {
    .sizes {
        &__control {
            background-color: transparent;
        }

    }
}

.pristine.sizes-selector {
    .sizes {
        &__control {
            border-color: $blue;
            border-width: 2px;
        }

    }
}

.tallas-table {
    width: 100%;
    margin: 2rem 0;
    text-align: center;
    border-collapse: collapse;

    // tr td:first-child {
    //     text-align: right;
    // }

    tr, td, th {
        border: 1px solid grey;
        padding: 5px 0;
    }
}


.add-to-cart button {
    width: 100%;
}



.bp3-dark {
    .sizes-selector {

        .sizes {

            &__control {
                background-color: transparent;
            }

            &__single-value {
                color: #fff;
            }

            &__menu {
                background-color: $black-primary;
            }

            &__option--is-focused {
                background-color: $black-additional;
            }

            &__option--is-selected {
                background-color: $blue !important;
            }

        }
    }
}
