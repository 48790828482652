@import "../Shared/styles/scss-variables.scss";

.side-filters {

    margin-bottom: 2rem;

    .bp3-checkbox {
        font-size: .8rem;

        &:hover {
            font-weight: 700;
        }
    }


    @media (max-width: 767px) {
        .bp3-radio {
            display: inline-block;
            width: 33.33333%;
        }
    }

}

.catalog-grid .transparent-bkg {
    padding: 0;

    .bp3-heading {
        font-weight: 300;
    }
}

.bp3-card {

    .square-container {
        background: url('./images/supersticion_logo-placeholder.png') no-repeat center center;
        background-size: 20px 20px;
    }

    .tarjeta_season {
        color: black
    }

}

.card {

    &-title {

        &__name {
            align-self:center;

            h4 { margin: 0 }
        }

        &__price {
            text-align: right;
            align-self: center;

            h4 { margin: 0 }

            p {
                text-decoration: line-through;
                font-size: 12px;
                margin: -7px 0 0 0;
                color: $red;
            }
        }
    }

    &-text {
        margin-top: 10px
    }

    &-item {
        margin-bottom: 1.4rem;
    }
}

.section {

    margin-bottom: 4rem;

    .section-title {
        font-family: 'Raleway', sans-serif;
        text-align: left;
        
        .bp3-heading {
            text-transform: uppercase;
            position: relative;
            &::after{ 
                content: '';
                width: 100%;
                position: absolute;
                height: 1px;
                left: 0;
                top: 8px;
                z-index: 1;
            }

            > span {
                position: relative;
                z-index: 2;
                padding-right: 1rem;
               
            }

            &.white {
                &::after {
                    background-color: $black-primary;
                }
                span {
                    background-color: $white-bkg;
                }
            }
            

            &.dark {
                &::after {
                    background-color: $white-bkg;
                }
                span {
                    background-color: $black-primary;
                }
            }
        }
    }

    .gridContainer--onlyfirst {
        .catalog-grid > .row > .card-item {
            &:not(:nth-child(-n + 6)) {
                display:none;
              }
        }
    }
}