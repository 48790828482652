.cart-container {
    max-width: 920px;
    margin: 0 auto;
    // margin-top: 2rem;
}

.item-data {
    align-self: center;
    .row {
        justify-content: flex-end;
    }
}

.cart-row hr {
    margin-bottom: 1rem;
}

.cart-row:last-child > hr {
    display: none;
}

.cart-item__name:hover {
    text-decoration: underline;
}

.price-table {
    width: 100%;
    margin: 2rem 0;
    text-align: center;

    .bp3-heading {
        font-weight: 300;
    }

    tr td:nth-child(1),tr th:nth-child(1) {
        width: 30%;
        text-align: right;
    }

    tr td:nth-child(2),tr th:nth-child(2) {
        width: 5%;
        text-align: right;
    }

    tr td:nth-child(3),tr th:nth-child(3) {
        width: 30%;
    }

    tr td:nth-child(4),tr th:nth-child(4) {
        width: 25%;
    }
    tr td:nth-child(5),tr th:nth-child(5) {
        text-align: left;
        width: 10%;
    }
}

.react-numeric-input b {
    cursor: pointer !important
}
