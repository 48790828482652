.content {
  min-height: calc(100vh - 56px);
}

.footer {
  height: 56px;
  margin-top: 2rem;
}

.whatsapp-icon, .instagrama {
    width: 26px;
}

.footer-redes {

    position: relative;

    &__container {

        margin-top: -6px;
        display: flex;
        justify-content: flex-end;
        // position: fixed;
        bottom: 0;

        a:hover svg path {
            fill: #137cbd;
        }
    }

    // margin-top: -6px;
    display: flex;
    justify-content: flex-end;

    &__el {
        // float: right;
        // margin-left: .6rem;
    }
}

@media (max-width: 767px) {

    .content {
        min-height: calc(100vh - (102px + 2rem));
    }

    .footer {
      height: 100%;
      margin-top: 2rem;
      text-align: center;

      .row {
          flex-direction: column-reverse;
          .text-right {
              text-align: center;
              margin-bottom: 2rem;
          }
      }

      &-redes {
        justify-content: center;
      }

    }

}
