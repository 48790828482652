@import "../Shared/styles/scss-variables";

.home-filters {
    margin-bottom: 1rem;
    padding: 12px !important;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;

    &__button {
        padding: 10px;
        border-radius: 2px;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            background-color: transparentize($white-primary, .4);
        }
    }

    .selectedFilter {
        background-color: $white-primary;

        .bp3-icon svg {
            fill: #48aff0;
        }
    }
}

.full-width {
    width: 100%;
}
