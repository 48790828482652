
// Colors
$black-primary: #18191a;
$black-secondary: #242526;
$black-additional: #283540;

$white-primary: #f2f2f2;
$white-bkg: #f5f8fa;

$blue: #137cbd;
$red: #F02849;
