@import "../Shared/styles/scss-variables.scss";

.mostSelledProducts {
    h1,h2,h3,h4,h5,h6 {
        font-family: 'Raleway', sans-serif;
    }

    .bp3-card {
        padding: 0;

        .card-title__name {
            padding-right: 1rem;
            .bp3-heading {
                margin-bottom: 0;
            }
        }

        &.no-available {
            .square-container {
                position: relative;
                &:hover::after {
                    content: 'PRODUCTO NO DISPONIBLE';
                    width: 100%;
                    height: 100%;
                    background-color: rgba($color: #000000, $alpha: 0.7);
                    position: absolute;
                    align-items: center;
                    display: flex;
                    top: 0;
                    left: 0;
                    justify-content: center;
                    color: $white-primary;
                }
            }
        }
    }

    .slick-slider {

        padding-bottom: 20px;

        .slick-prev, .slick-next {
            margin-top: -20px;
        }
        .slick-dots {
            bottom: 0;
        }
    }
}

.section-hero {
    position: relative;
    .bp3-heading {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        top: 0;
        bottom: 0;
        display: inline-table;
        margin: auto;
        color: $white-primary;
    }
}